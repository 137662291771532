<template>
  <div>週善行彙整</div>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"
        >請貼上 桌機Line 群組<br />某一個月的週善行內容</span
      >
    </div>
    <textarea
      class="form-control"
      aria-label="With textarea"
      v-model="userData.lineContent"
    ></textarea>
  </div>
  <div>
    <button class="btn btn-primary" @click="ImportClick">資料匯入</button>
  </div>
  <div>
    <button class="btn btn-primary" v-if="1 == 2" @click="test2Click">
      Test2
    </button>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="input-group">
        <span class="input-group-text">姓名</span>
        <select
          v-model="userData.selected"
          class="form-control"
          id="sel1"
          @click="PickOne($event)"
        >
          <option v-for="(n, index) in userData.userName" :key="index">
            {{ n }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-9"></div>
  </div>
  <table class="table" v-if="userData.MonthDayCount == 28">
    <thead>
      <tr>
        <th colspan="4">{{ userData.userMonth }}月份</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ userData.userYear }}1001</td>
        <td>{{ userData.D01 }}</td>
        <td>{{ userData.userYear }}1015</td>
        <td>{{ userData.D15 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1002</td>
        <td>{{ userData.D02 }}</td>
        <td>{{ userData.userYear }}1016</td>
        <td>{{ userData.D16 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1003</td>
        <td>{{ userData.D03 }}</td>
        <td>{{ userData.userYear }}1017</td>
        <td>{{ userData.D17 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1004</td>
        <td>{{ userData.D04 }}</td>
        <td>{{ userData.userYear }}1018</td>
        <td>{{ userData.D18 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1005</td>
        <td>{{ userData.D05 }}</td>
        <td>{{ userData.userYear }}1019</td>
        <td>{{ userData.D19 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1006</td>
        <td>{{ userData.D06 }}</td>
        <td>{{ userData.userYear }}1020</td>
        <td>{{ userData.D20 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1007</td>
        <td>{{ userData.D07 }}</td>
        <td>{{ userData.userYear }}1021</td>
        <td>{{ userData.D21 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1008</td>
        <td>{{ userData.D08 }}</td>
        <td>{{ userData.userYear }}1022</td>
        <td>{{ userData.D22 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1009</td>
        <td>{{ userData.D09 }}</td>
        <td>{{ userData.userYear }}1023</td>
        <td>{{ userData.D23 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1010</td>
        <td>{{ userData.D10 }}</td>
        <td>{{ userData.userYear }}1024</td>
        <td>{{ userData.D24 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1011</td>
        <td>{{ userData.D11 }}</td>
        <td>{{ userData.userYear }}1025</td>
        <td>{{ userData.D25 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1012</td>
        <td>{{ userData.D12 }}</td>
        <td>{{ userData.userYear }}1026</td>
        <td>{{ userData.D26 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1013</td>
        <td>{{ userData.D13 }}</td>
        <td>{{ userData.userYear }}1027</td>
        <td>{{ userData.D27 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1014</td>
        <td>{{ userData.D14 }}</td>
        <td>{{ userData.userYear }}1028</td>
        <td>{{ userData.D28 }}</td>
      </tr>
    </tbody>
  </table>

  <table class="table" v-if="userData.MonthDayCount == 29">
    <thead>
      <tr>
        <th colspan="4">{{ userData.userMonth }}月份</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ userData.userYear }}1001</td>
        <td>{{ userData.D01 }}</td>
        <td>{{ userData.userYear }}1016</td>
        <td>{{ userData.D16 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1002</td>
        <td>{{ userData.D02 }}</td>
        <td>{{ userData.userYear }}1017</td>
        <td>{{ userData.D17 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1003</td>
        <td>{{ userData.D03 }}</td>
        <td>{{ userData.userYear }}1018</td>
        <td>{{ userData.D18 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1004</td>
        <td>{{ userData.D04 }}</td>
        <td>{{ userData.userYear }}1019</td>
        <td>{{ userData.D19 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1005</td>
        <td>{{ userData.D05 }}</td>
        <td>{{ userData.userYear }}1020</td>
        <td>{{ userData.D20 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1006</td>
        <td>{{ userData.D06 }}</td>
        <td>{{ userData.userYear }}1021</td>
        <td>{{ userData.D21 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1007</td>
        <td>{{ userData.D07 }}</td>
        <td>{{ userData.userYear }}1022</td>
        <td>{{ userData.D22 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1008</td>
        <td>{{ userData.D08 }}</td>
        <td>{{ userData.userYear }}1023</td>
        <td>{{ userData.D23 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1009</td>
        <td>{{ userData.D09 }}</td>
        <td>{{ userData.userYear }}1024</td>
        <td>{{ userData.D24 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1010</td>
        <td>{{ userData.D10 }}</td>
        <td>{{ userData.userYear }}1025</td>
        <td>{{ userData.D25 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1011</td>
        <td>{{ userData.D11 }}</td>
        <td>{{ userData.userYear }}1026</td>
        <td>{{ userData.D26 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1012</td>
        <td>{{ userData.D12 }}</td>
        <td>{{ userData.userYear }}1027</td>
        <td>{{ userData.D27 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1013</td>
        <td>{{ userData.D13 }}</td>
        <td>{{ userData.userYear }}1028</td>
        <td>{{ userData.D28 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1014</td>
        <td>{{ userData.D14 }}</td>
        <td>{{ userData.userYear }}1029</td>
        <td>{{ userData.D29 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1015</td>
        <td>{{ userData.D15 }}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <table class="table" v-if="userData.MonthDayCount == 30">
    <thead>
      <tr>
        <th colspan="4">{{ userData.userMonth }}月份</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ userData.userYear }}1001</td>
        <td>{{ userData.D01 }}</td>
        <td>{{ userData.userYear }}1016</td>
        <td>{{ userData.D16 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1002</td>
        <td>{{ userData.D02 }}</td>
        <td>{{ userData.userYear }}1017</td>
        <td>{{ userData.D17 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1003</td>
        <td>{{ userData.D03 }}</td>
        <td>{{ userData.userYear }}1018</td>
        <td>{{ userData.D18 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1004</td>
        <td>{{ userData.D04 }}</td>
        <td>{{ userData.userYear }}1019</td>
        <td>{{ userData.D19 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1005</td>
        <td>{{ userData.D05 }}</td>
        <td>{{ userData.userYear }}1020</td>
        <td>{{ userData.D20 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1005</td>
        <td>{{ userData.D05 }}</td>
        <td>{{ userData.userYear }}1021</td>
        <td>{{ userData.D21 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1007</td>
        <td>{{ userData.D07 }}</td>
        <td>{{ userData.userYear }}1022</td>
        <td>{{ userData.D22 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1008</td>
        <td>{{ userData.D08 }}</td>
        <td>{{ userData.userYear }}1023</td>
        <td>{{ userData.D23 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1009</td>
        <td>{{ userData.D09 }}</td>
        <td>{{ userData.userYear }}1024</td>
        <td>{{ userData.D24 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1010</td>
        <td>{{ userData.D10 }}</td>
        <td>{{ userData.userYear }}1025</td>
        <td>{{ userData.D25 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1011</td>
        <td>{{ userData.D11 }}</td>
        <td>{{ userData.userYear }}1026</td>
        <td>{{ userData.D26 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1012</td>
        <td>{{ userData.D12 }}</td>
        <td>{{ userData.userYear }}1027</td>
        <td>{{ userData.D27 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1013</td>
        <td>{{ userData.D13 }}</td>
        <td>{{ userData.userYear }}1028</td>
        <td>{{ userData.D28 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1014</td>
        <td>{{ userData.D14 }}</td>
        <td>{{ userData.userYear }}1029</td>
        <td>{{ userData.D29 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1015</td>
        <td>{{ userData.D15 }}</td>
        <td>{{ userData.userYear }}1030</td>
        <td>{{ userData.D30 }}</td>
      </tr>
    </tbody>
  </table>
  <table class="table" v-if="userData.MonthDayCount == 31">
    <thead>
      <tr>
        <th colspan="4">{{ userData.userMonth }}月份</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ userData.userYear }}1001</td>
        <td>{{ userData.D01 }}</td>
        <td>{{ userData.userYear }}1017</td>
        <td>{{ userData.D17 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1002</td>
        <td>{{ userData.D02 }}</td>
        <td>{{ userData.userYear }}1018</td>
        <td>{{ userData.D18 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1003</td>
        <td>{{ userData.D03 }}</td>
        <td>{{ userData.userYear }}1019</td>
        <td>{{ userData.D19 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1004</td>
        <td>{{ userData.D04 }}</td>
        <td>{{ userData.userYear }}1020</td>
        <td>{{ userData.D20 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1005</td>
        <td>{{ userData.D05 }}</td>
        <td>{{ userData.userYear }}1021</td>
        <td>{{ userData.D21 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1006</td>
        <td>{{ userData.D06 }}</td>
        <td>{{ userData.userYear }}1022</td>
        <td>{{ userData.D22 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1007</td>
        <td>{{ userData.D07 }}</td>
        <td>{{ userData.userYear }}1023</td>
        <td>{{ userData.D23 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1008</td>
        <td>{{ userData.D08 }}</td>
        <td>{{ userData.userYear }}1024</td>
        <td>{{ userData.D24 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1009</td>
        <td>{{ userData.D09 }}</td>
        <td>{{ userData.userYear }}1025</td>
        <td>{{ userData.D25 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1010</td>
        <td>{{ userData.D10 }}</td>
        <td>{{ userData.userYear }}1026</td>
        <td>{{ userData.D26 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1011</td>
        <td>{{ userData.D11 }}</td>
        <td>{{ userData.userYear }}1027</td>
        <td>{{ userData.D27 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1012</td>
        <td>{{ userData.D12 }}</td>
        <td>{{ userData.userYear }}1028</td>
        <td>{{ userData.D28 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1013</td>
        <td>{{ userData.D13 }}</td>
        <td>{{ userData.userYear }}1029</td>
        <td>{{ userData.D29 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1014</td>
        <td>{{ userData.D14 }}</td>
        <td>{{ userData.userYear }}1030</td>
        <td>{{ userData.D30 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1015</td>
        <td>{{ userData.D15 }}</td>
        <td>{{ userData.userYear }}1031</td>
        <td>{{ userData.D31 }}</td>
      </tr>
      <tr>
        <td>{{ userData.userYear }}1016</td>
        <td>{{ userData.D16 }}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { reactive, computed } from "vue";
export default {
  setup() {
    const userData = reactive({
      selected: null,
      lineContent: "",
      regOrilist: [],
      regCleanlist: [],
      datalist: [],
      userName: [],
      userYear: "",
      userMonth: "",
      TestData: ["paladin", "ruby"],
      D01: "",
      D02: "",
      D03: "",
      D04: "",
      D05: "",
      D06: "",
      D07: "",
      D08: "",
      D09: "",
      D10: "",
      D11: "",
      D12: "",
      D13: "",
      D14: "",
      D15: "",
      D16: "",
      D17: "",
      D18: "",
      D19: "",
      D20: "",
      D21: "",
      D22: "",
      D23: "",
      D24: "",
      D25: "",
      D26: "",
      D27: "",
      D28: "",
      D29: "",
      D30: "",
      D31: "",

      MonthDayCount: computed(() => {
        var vYear = GetUserYear();
        var vMonth = GetUserMonth();
        var d = new Date(vYear, vMonth, 0);
        var n = d.getDate();

        return n;
      }),
    });

    function clearEachDay() {
      userData.D01 = "";
      userData.D02 = "";
      userData.D03 = "";
      userData.D04 = "";
      userData.D05 = "";
      userData.D06 = "";
      userData.D07 = "";
      userData.D08 = "";
      userData.D09 = "";
      userData.D10 = "";
      userData.D11 = "";
      userData.D12 = "";
      userData.D13 = "";
      userData.D14 = "";
      userData.D15 = "";
      userData.D16 = "";
      userData.D17 = "";
      userData.D18 = "";
      userData.D19 = "";
      userData.D20 = "";
      userData.D21 = "";
      userData.D22 = "";
      userData.D23 = "";
      userData.D24 = "";
      userData.D25 = "";
      userData.D26 = "";
      userData.D27 = "";
      userData.D28 = "";
      userData.D29 = "";
      userData.D30 = "";
      userData.D31 = "";
    }

    function test2Click() {
      // Object.values(userData.datalist).forEach((x) => console.log("=>", x));
      // Object.values(userData.datalist).forEach((x) =>
      //   x.forEach((y) => {
      //     if (y.name.trim() == userData.selected.trim()) {
      //       console.log("====>", y);
      //     }
      //   })
      // );

      // console.log("MonthDayCount=", userData.MonthDayCount);
      PickOne();

      // for (let v of Object.values(userData.datalist))
      //   console.log("Object Value =>", v.name);
    }

    function PickOne() {
      //取得下拉清單中，某位同學的所有善行紀錄

      if (userData.selected == null) return;

      var vResult = [];
      Object.values(userData.datalist).forEach((x) => {
        if (x[0].name.trim() == userData.selected.trim()) vResult.push(x);
      });

      clearEachDay();

      vResult[0].forEach((x) => {
        switch (String(x.vSDate).substr(6, 2)) {
          case "01":
            userData.D01 = x.value;
            break;
          case "02":
            userData.D02 = x.value;
            break;
          case "03":
            userData.D03 = x.value;
            break;
          case "04":
            userData.D04 = x.value;
            break;
          case "05":
            userData.D05 = x.value;
            break;
          case "06":
            userData.D06 = x.value;
            break;
          case "07":
            userData.D07 = x.value;
            break;
          case "08":
            userData.D08 = x.value;
            break;
          case "09":
            userData.D09 = x.value;
            break;
          case "10":
            userData.D10 = x.value;
            break;
          case "11":
            userData.D11 = x.value;
            break;
          case "12":
            userData.D12 = x.value;
            break;
          case "13":
            userData.D13 = x.value;
            break;
          case "14":
            userData.D14 = x.value;
            break;
          case "15":
            userData.D15 = x.value;
            break;
          case "16":
            userData.D16 = x.value;
            break;
          case "17":
            userData.D17 = x.value;
            break;
          case "18":
            userData.D18 = x.value;
            break;
          case "19":
            userData.D19 = x.value;
            break;
          case "20":
            userData.D20 = x.value;
            break;
          case "21":
            userData.D21 = x.value;
            break;
          case "22":
            userData.D22 = x.value;
            break;
          case "23":
            userData.D23 = x.value;
            break;
          case "24":
            userData.D24 = x.value;
            break;
          case "25":
            userData.D25 = x.value;
            break;
          case "26":
            userData.D26 = x.value;
            break;
          case "27":
            userData.D27 = x.value;
            break;
          case "28":
            userData.D28 = x.value;
            break;
          case "29":
            userData.D29 = x.value;
            break;
          case "30":
            userData.D30 = x.value;
            break;
          case "31":
            userData.D31 = x.value;
            break;
        }
      });
    }

    function ImportClick() {
      //初始化
      userData.regOrilist = [];
      userData.userName = [];

      var re2 = /[\S]*[ ]*[0-9]*\/[0-9]*[ ]*(\(|（).(\)|）)[ ]*[0-9]{5}/g;

      var result2;
      while ((result2 = re2.exec(userData.lineContent)) !== null) {
        userData.regOrilist.push(result2[0]);
      }

      console.log(userData.lineContent);
      console.log("result2:", userData.regOrilist);
      CleanUserData();

      //test Group by
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      //userData.datalist=groupBy;
      userData.datalist = groupBy(userData.regCleanlist, "name");

      Object.keys(userData.datalist).forEach((element) => {
        userData.userName.push(element);
      });
    }

    function CleanUserData() {
      var vYear = GetUserYear();
      userData.regOrilist.forEach((x) => regOrilistAdd(x, vYear));
      console.log("regCleanlist:", userData.regCleanlist);
    }

    function regOrilistAdd(item, userYear) {
      var reDate = /[0-9]*\/[0-9]*[ ]*(\(|（).(\)|）)[ ]*/g;
      var result;
      result = reDate.exec(item);
      if (result != null) {
        var vDate = result[0];
        var arrayInfo = item.split(vDate);
        var vName = arrayInfo[0];
        var vValue = arrayInfo[1];

        if (vName == "") {
          //如果 userData.regCleanlist 已經有值，挑選最近一筆
          if (userData.regCleanlist.length > 0) {
            vName =
              userData.regCleanlist[userData.regCleanlist.length - 1].name;
          }
        }

        //替換全形的括弧
        vDate = vDate.replace("（", "(");
        vDate = vDate.replace("）", ")");

        //轉換標準西元年月日
        var arrayDate = vDate.split("/");
        var vStandardDate = "";
        switch (arrayDate.length) {
          case 2:
            vStandardDate =
              userYear.toString() +
              AddZeroForDate(arrayDate[0].toString().trim()) +
              AddZeroForDate(arrayDate[1].toString().trim());
            break;

          case 3:
            vStandardDate =
              arrayDate[0].toString().trim() +
              AddZeroForDate(arrayDate[1].toString().trim()) +
              AddZeroForDate(arrayDate[2].toString().trim());
            break;
        }

        userData.regCleanlist.push({
          name: vName,
          date: vDate,
          vSDate: vStandardDate,
          value: vValue,
        });

        console.log("userYear--->", userYear);
        console.log("vStandardDate--->", vStandardDate);
      }
    }

    function GetUserYear() {
      var nowYear = new Date().getFullYear();
      var vYearResult = nowYear;

      //從 Line 內容找，有找到就用 Line 內容的，沒有就用系統當天的年份
      var reYear = /[0-9]{4}.[0-9]{2}.[0-9]{2}/g;
      var result;
      result = reYear.exec(userData.lineContent);
      if (result != null) {
        vYearResult = result[0].substr(0, 4);
      }

      userData.userYear = vYearResult;
      return vYearResult;
    }

    function GetUserMonth() {
      var vMonthResult = "";

      //從 Line 內容找，有找到就用 Line 內容的，沒有就用系統當天的年份
      var reYear = /[0-9]{4}.[0-9]{2}.[0-9]{2}/g;
      var result;
      result = reYear.exec(userData.lineContent);
      if (result != null) {
        vMonthResult = result[0].split(".")[1];
        console.log("check User Month:", result[0]);
      }

      console.log("check User Month Result:", vMonthResult);

      userData.userMonth = vMonthResult;
      return vMonthResult;
    }

    function AddZeroForDate(strOri) {
      console.log("date ori:", strOri);
      console.log("date convert:", strOri.length < 2 ? "0" + strOri : strOri);
      return strOri.split("(")[0].length < 2 ? "0" + strOri : strOri;
    }

    return {
      userData,
      ImportClick,
      test2Click,
      CleanUserData,
      regOrilistAdd,
      GetUserYear,
      GetUserMonth,
      clearEachDay,
      AddZeroForDate,
      PickOne,
    };
  },
};
</script>

<style>
</style>